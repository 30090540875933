import axios from 'axios';
import Qs from 'qs';

export const makeApi = () => {
  const config = useRuntimeConfig();

  const api = axios.create({
    baseURL: config.public.api.url,
    headers: { 'X-API-Key': config.public.api.key },
    paramsSerializer: {
      serialize: (params) => {
        return Qs.stringify(params, { arrayFormat: 'indices' });
      },
    },
  });

  return api;
};
