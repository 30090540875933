import debounce from 'lodash/debounce';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useInfoStore = defineStore('info', () => {
  function getBaseURL() {
    if (process.server) {
      return nuxtApp.ssrContext?.event.context.baseURL;
    }

    return location.origin;
  }

  function getScreen() {
    if (!process.client) return;

    if (window.innerWidth < 640) {
      return 'xs';
    }
  }

  function setBaseURL(value: string) {
    baseURL.value = value;
  }

  const initInteraction = () => {
    const events = [
      'mousedown',
      'mousemove',
      'touchstart',
      'scroll',
      'keydown',
    ];

    const handle = () => {
      hasInteraction.value = true;
      events.forEach((event) => document.removeEventListener(event, handle));
    };

    events.forEach((event) =>
      document.addEventListener(event, handle, { once: true, passive: true })
    );
  };

  const updateScrollOnTop = debounce(() => {
    scrollOnTop.value = window.scrollY === 0;
  });

  const updateScreen = debounce(() => {
    screen.value = getScreen();
  });

  const nuxtApp = useNuxtApp();
  const baseURL = ref(getBaseURL());
  /**
   * When user did any interaction.
   */
  const hasInteraction = ref(false);
  const screen = ref<'xs' | undefined>(getScreen());
  /**
   * Is scroll position on top?
   */
  const scrollOnTop = ref(true);

  if (process.client) {
    initInteraction();
    addEventListener('resize', updateScreen);
    addEventListener('scroll', updateScrollOnTop);
  }

  return { baseURL, hasInteraction, screen, scrollOnTop, setBaseURL };
});
