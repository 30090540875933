import type { RouterConfig } from '@nuxt/schema';
import qs from 'qs';

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const str = qs.stringify(query, { arrayFormat: 'indices', encode: false });
    return str;
  },
};
